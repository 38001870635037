<template>
  <div class="Login">
    <header></header>
    <div class="content">
      <video autoplay muted loop src="../../assets/video/island.mp4"></video>
      <main>
        <div class="title">
          <h1>多多兔收银系统</h1>
          <h2>Duoduo Rabbit Cashier System</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio voluptate soluta tenetur eum non nemo excepturi accusamus consequuntur reprehenderit dolore at, tempora debitis perferendis blanditiis quam inventore minus
            praesentium
            itaque.</p>
        </div>
        <div class="usform">
          <p>Login</p>
          <el-form :label-position="labelPosition" label-width="80px" :model="form" ref="form">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="form.password" type="password" placeholder="密码"></el-input>
            </el-form-item>
            <el-button type="primary" class="sub" @click="Login">登录</el-button>
            <div class="share-button">
              <span>
                <i class="el-icon-s-custom"></i> 更多!
              </span>
              <a href="#">
                <i class="el-icon-eleme"></i>
              </a>
              <a href="#">
                <i class="el-icon-phone"></i>
              </a>
              <a href="#">
                <i class="el-icon-upload"></i>
              </a>
              <a href="#">
                <i class="el-icon-s-promotion"></i>
              </a>
            </div>
          </el-form>
        </div>
      </main>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: "right",
      form: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          // required: 非空规则
          // message: 提示信息
          // trigger: 触发的方式  blur  change
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "change"
          }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "change"
          }
        ]
      }
    }
  },
  methods: {
    async Login() {
      const res = await this.axios.post('cashier/login', {
        username: this.form.username,
        password: this.form.password
      })
      console.log(res)
      if (res.code === 200) {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('user', JSON.stringify(res.data.user))
        this.$router.push({ path: '/memberList' })
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>



<style lang="less" scoped>
.Login {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .content {
    video {
      width: 100vw;
      z-index: -1;
      position: absolute;
    }
    main {
      position: absolute;
      top: 10%;
      left: 10%;
    }
    .title {
      color: #fff;
      width: 40%;
      h2 {
        font-size: 50px;
      }
      p {
        color: #fff;
      }
    }

    .usform {
      width: 40%;
      padding: 20px;
      padding-top: 30px;
      border-radius: 10px;
      margin-top: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      p {
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form-item__label {
        color: #fff;
      }
      .sub {
        float: right;
      }
      .sub2 {
        float: right;
        margin-right: 10px;
      }
      .share-button {
        width: 180px;
        height: 40px;
        // background: #dfe6e9;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 0.3s linear;
      }

      .share-button:hover {
        transform: scale(1.1);
      }

      .share-button span {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #2d3436;
        color: #f1f1f1;
        text-align: center;
        line-height: 40px;
        z-index: 999;
        transition: 0.6s linear;
        border-radius: 40px;
      }

      .share-button:hover span {
        transform: translateX(-100%);
        transition-delay: 0.3s;
      }

      .share-button a {
        flex: 1;
        font-size: 26px;
        color: #fff;
        text-align: center;
        transform: translateX(-100%);
        opacity: 0;
        transition: 0.3s linear;
      }

      .share-button:hover a {
        opacity: 1;
        transform: translateX(0);
      }

      .share-button a:nth-of-type(1) {
        transition-delay: 1s;
      }

      .share-button a:nth-of-type(2) {
        transition-delay: 0.8s;
      }

      .share-button a:nth-of-type(3) {
        transition-delay: 0.6s;
      }

      .share-button a:nth-of-type(4) {
        transition-delay: 0.4s;
      }
    }
  }
}
</style>